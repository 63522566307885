import * as React from "react";
import { Helmet } from "react-helmet";

const SEO = () => {
  return (
    <Helmet
      title="João Gomes - Fullstack Developer"
      htmlAttributes={{ lang: "en" }}
    >
      <meta name="title" content="João Gomes - Fullstack Developer" />
      <meta
        name="description"
        content="Personal site of João Gomes - Fullstack Developer"
      />
      <meta name="image" content="https://joaogomes.work/logo.png" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://joaogomes.work/" />
      <meta property="og:title" content="João Gomes - Fullstack Developer" />
      <meta
        property="og:description"
        content="Personal site of João Gomes - Fullstack Developer"
      />
      <meta property="og:image" content="https://joaogomes.work/logo.png" />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://joaogomes.work/" />
      <meta
        property="twitter:title"
        content="João Gomes - Fullstack Developer"
      />
      <meta
        property="twitter:description"
        content="Personal site of João Gomes - Fullstack Developer"
      />
      <meta
        property="twitter:image"
        content="https://joaogomes.work/logo.png"
      />
    </Helmet>
  );
};

export default SEO;
