import { css } from "@emotion/react";

export const customStyles = css`
  body {
    background: white;
    color: black;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  body {
    font-family: "Trebuchet MS", Verdana, Geneva, Tahoma, sans-serif;
  }
`;
